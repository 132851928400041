.stars-background {
  z-index: 2;
  width: 100%;
  height: 100%;
  max-height: max-content;
  position: absolute;
  top: 0;
  margin: 0px;
  overflow: hidden;
}
.stars-background {
  z-index: -1;
}

main:has(.stars-background.active) {
  background-color: transparent;
  z-index: 3;
}
