@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scroll::-webkit-scrollbar {
  display: none !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Onest", sans-serif;
  height: 100%;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.globeParent {
  position: absolute;
  bottom: 0px;
}

.globe {
  /* animation: rotate 20s linear infinite; */
}

.cursor {
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.globe-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px; /* Adjust this value to show more or less of the globe */
  overflow: hidden;
  z-index: 1; /* Make sure this is behind other elements */
}

.globe {
  width: 100%;
  height: auto;
  animation: rotate 20s linear infinite;
  transform-origin: center; /* Ensure the globe rotates around its center */
  opacity: 0.5; /* Make the globe semi-transparent */
}

.loading_parent {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 9999;
  backdrop-filter: blur(2px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.MuiDialog-root {
  z-index: 9999999 !important;
}
.MuiPopover-root {
  z-index: 9999999999 !important;
}

.backgroundParent::-webkit-scrollbar {
  display: none !important;
}
@font-face {
  font-family: "redhatdisplay-s";
  src: url(../public/RedHatDisplay-SemiBold.ttf);
}
@font-face {
  font-family: "redhatdisplay-r";
  src: url(../public/RedHatDisplay-Regular.ttf);
}
@font-face {
  font-family: "redhatdisplay-b";
  src: url(../public/RedHatDisplay-Bold.ttf);
}
