.backgroundParent {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
  &::-webkit-scrollbar {
    display: none !important;
  }

  .lottie-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media screen and (max-width: 499px) {
      display: none;
    }
  }

  .lottie-bg-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;

    .bg-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .globe-3d {
      position: absolute;
      top: 70%;
      left: 50%;
      width: 600px;
      height: 600px;
      transform: translateX(-50%);
      object-fit: cover;
    }

    @media screen and (min-width: 500px) {
      display: none;
    }
  }

  .mainLanding {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
  }
  .upperPortion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
  }
  .upper__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    // transition: 3s ease;
    transition: opacity 0.5s ease;
    opacity: 1;
    @media screen and (max-width: 500px) {
      gap: 15px;
      // padding: 0 15px;
    }

    &.fade-out {
      opacity: 0;
    }

    .lottie-animation {
      width: 380px;
      height: 75px;
      // padding-top: 64px;
      margin: 0 auto;
      @media screen and (max-width: 700px) {
      height: 40px;
      width: 300px;
        padding-top: 0px;
      }
    }

    .text__parent {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .heading {
        background: linear-gradient(
          90deg,
          #f4f6f9 57.12%,
          #00d2f2 73.19%,
          #1d8ffc 96.74%,
          rgba(29, 143, 252, 0) 122.88%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 54px;
        font-style: normal;
        font-weight: 800;
        line-height: 75px;
        margin: 0;
        max-width: 900px;
        @media screen and (max-width: 900px) {
          font-size: 50px;
          line-height: 59px;
        }
        @media screen and (max-width: 500px) {
          font-size: 28px;
          line-height: 30px;
        }
      }

      .info {
        color: #b4ccf0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 138%;
        max-width: 900px;
        margin: 0;
        @media screen and (max-width: 900px) {
          font-size: 18px;
        }
        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 600px;

      @media screen and (max-width: 850px) {
        max-width: 100%;
      }

      .input_parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 7px 7px 30px;
        border-radius: 20px;
        border: 1.2px solid #00d2f2;

        background: rgba(0, 27, 67, 0.4);

        box-shadow: 24px 211px 59px 0px rgba(24, 214, 243, 0),
          15px 135px 54px 0px rgba(24, 214, 243, 0.01),
          9px 76px 46px 0px rgba(24, 214, 243, 0.05),
          4px 34px 34px 0px rgba(24, 214, 243, 0.09),
          1px 8px 19px 0px rgba(24, 214, 243, 0.1);
        backdrop-filter: blur(10px);
        @media screen and (max-width: 500px) {
          padding: 4px;
          padding-left: 8px;
        }

        .text__input {
          width: calc(600px - 210px);
          border: none;
          outline: none;
          background-color: transparent !important;
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 138%;
          margin-right: 5px;
          &::placeholder {
            color: #fcfcfc;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 138%;
          }
          @media screen and (max-width: 850px) {
            width: 100%;
          }
        }
      }

      .checkbox__parent {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 7px;
        .checkbox__text {
          color: #fff;
          text-align: center;
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: 138%; /* 26.22px */
          @media screen and (max-width: 550px) {
            font-size: 12px;
          }
          span {
            color: #fff;
            text-align: center;
            // font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 138%;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
      }
    }
    .timer {
      padding: 21px 40px;
      padding-bottom: 16px !important;
      border-radius: 20.027px;
      border: 1.502px solid #00d2f2;
      background: rgba(0, 27, 67, 0.62);
      box-shadow: 0px 4.005px 4.005px 0px rgba(16, 172, 248, 0.25),
        0px 2.003px 4.005px 0px rgba(29, 48, 106, 0.16);
      backdrop-filter: blur(99.69999694824219px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 730px;
      box-sizing: border-box;
      // gap: 32px;
      z-index: 6666;
      @media screen and (max-width: 600px) {
        width: 100%;
        padding: 10px 20px;
      }

      .days {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-width: 120px;
        @media screen and (max-width: 600px) {
          min-width: auto;
        }

        .count {
          color: #f1f4f9;

          text-align: center;
          font-size: 64.088px;
          font-style: normal;
          font-weight: 700;
          line-height: 86.518px; /* 135% */
          @media screen and (max-width: 600px) {
            font-size: 34.088px;
            line-height: 58px;
          }
        }
        .count_name {
          color: #f1f4f9;
          text-align: center;
          font-family: Onest;
          font-size: 16.022px;
          font-style: normal;
          font-weight: 400;
          line-height: 138%; /* 22.11px */
        }
      }
      .colon {
        color: #f1f4f9;
        text-align: center;
        font-size: 48.066px;
        font-style: normal;
        font-weight: 700;
        line-height: 86.518px;
      }
    }
  }

  .bottom__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 62px;
    z-index: 6666;
    transition: opacity 0.5s ease;
    opacity: 1;
    @media screen and (max-width: 500px) {
      padding: 0 15px;
    }

    &.fade-out {
      opacity: 0;
    }

    .bottom__text {
      color: #b4ccf0;

      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px; /* 140% */
      max-width: 790px;
      @media screen and (max-width: 800px) {
        max-width: 100%;
        font-size: 14px;
      }
    }
    .copyRight {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .actionButtons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: fixed;
    top: 40%;
    right: 80px;
    z-index: 6666;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  // .globeParent {
  //   position: absolute;
  //   bottom: 0px;
  //   .globe {
  //     background-size: cover;
  //     background-position: center;
  //     background-repeat: no-repeat;
  //   }
  // }
}

.gradient-heading {
  background: linear-gradient(
    90deg,
    #f4f6f9 57.12%,
    #00d2f2 73.19%,
    #1d8ffc 96.74%,
    rgba(29, 143, 252, 0) 122.88%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 58px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media screen and (max-width: 600px) {
    font-size: 19px;
    padding: 2px;
  }

  @media screen and (max-width: 800px) {
    font-size: 30px;
    padding: 2px;
  }
}
