/* dots.scss */
@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.dot {
  position: absolute;
  width: 100px;
  height: 100px;
  animation: ping var(--animation-duration, 2s) infinite;
  animation-delay: var(--animation-delay, 0s);
}
