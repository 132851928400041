.button {
  border-radius: 12px;
  border: none;
  outline: none;
  border: 1px solid #096592;
  background: linear-gradient(180deg, #00d2f2 0%, #247fff 100%);
  box-shadow: 0px 5px 7.6px 0px rgba(255, 255, 255, 0.32) inset,
    0px 4px 4px 0px rgba(16, 172, 248, 0.25);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  padding: 0 30px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  @media screen and (max-width: 550px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.button:disabled {
  cursor: not-allowed !important;
}
