@keyframes spinColor {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinning-border {
  display: inline-block;
  padding: 2px; /* Adjust padding to control the size of the border */
  border-radius: 50px;
  background: conic-gradient(#00ffad, #00ddff, #4093ff, #00ffad);
}

.spinning-border-inner {
  border-radius: 50px;
  background: black; /* Inner content background */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Fill the spinning border */
  width: 100%;
  padding: 6px; /* Optional for inner content spacing */
}
.clamp {
  font-size: clamp(1.3rem, 1.5vw, 1.875rem);
}
