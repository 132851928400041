.second {
  color: red;
  margin-bottom: 45px;
}
.mobile-content {
  display: none;
}
.mobile-right1 {
  display: none;
}
.menuhidden {
  display: block;
}
.popup {
  width: 100%;
}
.summary h1 {
  display: none;
}
.summary h2 {
  display: none;
}
.summary p {
  display: none;
}
@media (max-width: 745px) {
  .mobile-content {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .menuhidden {
    display: none;
  }
  .popup {
    position: absolute;
    right: 17px;
    top: 65px;
    width: 70%;
  }
  .mobile-width {
    max-width: 100%;
  }
  .mobile-remove {
    display: none;
  }
}
